<div class="d-flex tw-justify-center">
  <div>
    <img
      src="/assets/img/Spinner.gif"
      class="loading-spinner"
      alt="loading..."
    />
    <small class="text-center">{{ title }}</small>
  </div>
</div>
