// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  password: "greenworld",
  baseUrl: "https://apiupgrade.psychonline.com/api/",
  apiUrl: "https://apiupgrade.psychonline.com/",
  newApiUrl: "https://apiv2staging.psychonline.com/api/",
  localApiUrl: "http://localhost:5000/api/",
  apiV3Url: "https://nodestaging.psychonline.com/api/",
  stripePublishableKey: "pk_test_l2GpMhv2189rleiK7ggju7sz",
  socketIoApi: "https://apisocket.psychonline.com",
  team_id: "1",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
