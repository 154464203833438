import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utctime'
})
export class UtctimePipe implements PipeTransform {

  transform(value: any): any {
    return moment.utc(value, 'h:m A').local().format('h:mm A');
  }
}
