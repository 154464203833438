<div>
  <nav
    class="tw-top-0 tw-absolute tw-z-50 tw-w-full tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-px-2 tw-py-3 tw-navbar-expand-lg"
  >
    <div
      class="tw-container tw-px-4 tw-mx-auto tw-flex tw-flex-wrap tw-items-center tw-justify-between"
    >
      <div
        class="tw-w-full tw-relative flex tw-justify-between lg:tw-w-auto lg:tw-static lg:tw-block lg:tw-justify-start"
      >
        <a
          class="tw-text-white tw-text-sm tw-font-bold tw-leading-relaxed tw-inline-block tw-mr-4 tw-py-2 tw-whitespace-nowrap tw-uppercase"
        >
          <img src="{{ logo }}" width="25%" />
        </a>
      </div>
    </div>
  </nav>
  <main class="cscroll">
    <section
      class="tw-relative tw-w-full tw-h-full tw-py-40 tw-min-h-screen"
      style="overflow: auto !important"
    >
      <div
        class="bg-i tw-absolute tw-top-0 tw-w-full tw-h-full tw-bg-blueGray-800 tw-bg-no-repeat tw-bg-full"
        style="background-image: url('assets/img/register_bg_2.png')"
      ></div>
      <router-outlet></router-outlet>
    </section>
  </main>
</div>
