import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "datepipe",
})
export class DatepipePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return "";
    }
    return moment.utc(value).local().format("DD-MM-YYYY");
  }
}

@Pipe({
  name: "datetimepipe",
})
export class DateTimePipe implements PipeTransform {
  transform(value: any): any {
    const temp_value = value.trim();
    const slot_value = temp_value.split(" ");
    return (
      moment.utc(temp_value, "Y-M-D h:m A").local().format("MM/DD/YYYY") +
      " " +
      moment
        .utc(slot_value[1] + " " + slot_value[2], "h:m A")
        .local()
        .format("hh:mm A")
    );
  }
}
