import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utc'
})
export class UtcPipe implements PipeTransform {

  transform(value: any, format: any): any {
    var time = value.split(' ');
    if (format) {
      return moment.utc(time[1], 'h:m A').local().format('h:mm A');
    } else {
      return moment.utc(time[1], 'h:m A').local();
    }
  }
}
