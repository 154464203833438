import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService, UtilityService } from "src/app/shared";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  previousUrls: Array<String>;
  loginForm: FormGroup;
  constructor(
    public router: Router,
    public utilityService: UtilityService,
    public apiService: ApiService,
    public formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.previousUrls = this.utilityService.getPreviousUrls();
  }
  formInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  onSubmit() {
    this.spinner.show();
    if (
      this.loginForm.valid &&
      this.loginForm.value["email"] == "clinicadmin@psychonline.com" &&
      this.loginForm.value["password"] == environment["password"]
    ) {
      this.apiService.login(this.loginForm.value).subscribe(
        (response) => {
          this.spinner.hide();
          localStorage.setItem("role", response["data"].role);
          localStorage.setItem("user", JSON.stringify(response["data"]));
          localStorage.setItem("token", response["data"].token);
          this.router.navigate(["admin/dashboard"]);
        },
        (error) => {
          this.spinner.hide();
          console.log("login error: ", error);
          this.toastr.error("Invalid details");
        }
      );
    } else {
      this.spinner.hide();
      this.toastr.error("Enter valid details");
    }
  }
}
