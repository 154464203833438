import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import 'hammerjs';


Sentry.init({
  dsn: "https://9e223daf50f54dbd8a0cbad13352c224@o427256.ingest.sentry.io/5712656",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", /^\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () { };
  }
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
