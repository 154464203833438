import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiService } from "./services/api.service";
import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { SearchPipe } from "./pipes/search.pipe";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { ConfirmBoxComponent } from "./components/confirm-box/confirm-box.component";
import { DatepipePipe } from "./pipes/datepipe.pipe";
import { DateTimePipe } from "./pipes/datepipe.pipe";
import { MatNativeDateModule } from "@angular/material/core";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UtctimePipe } from "./pipes/utctime.pipe";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { UtcPipe } from "./pipes/utc.pipe";

import { SafehtmlPipe } from "./pipes/safehtml.pipe";
import { environment } from "src/environments/environment";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { GetInitalPipe } from "./pipes/get-inital.pipe";
import { MatStepperModule } from "@angular/material/stepper";
import { ConvertDatePipe } from "./pipes/convert-date.pipe";

import { FormatDateofservicePipe } from "./pipes/format-dateofservice.pipe";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LoaderComponent } from "./components/loader/loader.component";

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    NgbModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatTabsModule,
    MatIconModule,
    MatRadioModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatListModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    DragDropModule,
    InfiniteScrollModule,
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: "decreasing",
      timeOut: 5000,
      autoDismiss: true,
      tapToDismiss: false,
      toastClass: "custome-toast",
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    NgxJsonViewerModule,
    FormlyBootstrapModule,
  ],
  declarations: [
    SearchPipe,
    ConfirmBoxComponent,
    DatepipePipe,
    DateTimePipe,
    UtctimePipe,
    UtcPipe,

    SafehtmlPipe,

    GetInitalPipe,
    ConvertDatePipe,

    FormatDateofservicePipe,

    LoaderComponent,
  ],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxSpinnerModule,
    ToastrModule,
    RouterModule,
    NgbModule,
    CalendarModule,
    SearchPipe,
    DatepipePipe,
    DateTimePipe,
    UtctimePipe,
    GetInitalPipe,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatTabsModule,
    MatIconModule,
    MatRadioModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    MatListModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    DragDropModule,
    FormlyModule,
    FormlyBootstrapModule,
    UtcPipe,
    SafehtmlPipe,
    FormatDateofservicePipe,
    ConvertDatePipe,

    NgxJsonViewerModule,
    InfiniteScrollModule,

    LoaderComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiService, multi: true },
  ],
  entryComponents: [ConfirmBoxComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  // constructor(private dateAdapter: DateAdapter<Date>) {
  //   dateAdapter.setLocale('en-GB'); // DD/MM/YYYY
  // }
}
