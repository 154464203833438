import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateofservice'
})
export class FormatDateofservicePipe implements PipeTransform {

  transform(input: any) {
    if (input == null) {
      return '';
    }
    let splitted_input = input.split('');
    let year = splitted_input[0] + splitted_input[1] + splitted_input[2] + splitted_input[3];
    let month = splitted_input[4] + splitted_input[5];
    let day = splitted_input[6] + splitted_input[7];
    var date = month + '/' + day + '/' + year;
    return date;
  }

}
