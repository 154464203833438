import { Component, OnInit } from "@angular/core";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { UtilityService } from "./shared";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "./shared";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  logo: any;
  previousUrl: string = "";
  currentUrl: string = "";

  constructor(
    private router: Router,
    private apiService: ApiService,
    public utilityService: UtilityService,
    private spinnerService: NgxSpinnerService
  ) {}
  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.utilityService.setPreviousUrl(this.previousUrl);
      });
  }
}
