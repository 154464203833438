<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  name="primary"
  size="default"
  color="#fff"
  type="ball-spin-clockwise-fade"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>

<div class="tw-container tw-mx-auto tw-px-4 tw-h-full">
  <div
    class="tw-flex tw-content-center tw-items-center tw-justify-center tw-h-full"
  >
    <div class="tw-lg\:w-4\/12 tw-px-4">
      <div
        class="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-break-words tw-w-full tw-mb-6 tw-shadow-lg tw-rounded-lg tw-bg-blueGray-200 tw-border-0"
      >
        <div
          class="tw-flex-auto tw-px-4 tw-lg:tw-px-10 tw-py-10 tw-px-6"
          style="width: 390px"
        >
          <div class="tw-text-center tw-mb-3">
            <h6 class="tw-text-blueGray-500 tw-text-sm tw-font-bold">
              Sign in with credentials
            </h6>
          </div>
          <form [formGroup]="loginForm">
            <div class="tw-relative tw-w-full tw-mb-3">
              <label
                class="tw-block tw-uppercase tw-text-blueGray-600 tw-text-xs tw-font-bold tw-mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="email"
                formControlName="email"
                name="email"
                (keyup.enter)="onSubmit()"
                class="tw-border-0 tw-px-3 tw-py-3 tw-placeholder-blueGray-300 tw-text-blueGray-600 tw-bg-white tw-rounded tw-text-sm tw-shadow focus:tw-outline-none focus:tw-ring tw-w-full tw-ease-linear tw-transition-all tw-duration-150"
                placeholder="Email"
              />
            </div>

            <div class="tw-relative tw-w-full tw-mb-3">
              <label
                class="tw-block tw-uppercase tw-text-blueGray-600 tw-text-xs tw-font-bold tw-mb-2"
                htmlFor="grid-password"
              >
                Password
              </label>
              <input
                type="password"
                formControlName="password"
                name="password"
                class="tw-border-0 tw-px-3 tw-py-3 tw-placeholder-blueGray-300 tw-text-blueGray-600 tw-bg-white tw-rounded tw-text-sm tw-shadow focus:tw-outline-none focus:tw-ring tw-w-full tw-ease-linear tw-transition-all tw-duration-150"
                placeholder="Password"
                (keyup.enter)="onSubmit()"
              />
            </div>

            <div class="tw-text-center tw-mt-6">
              <button
                (click)="onSubmit()"
                id="loginBtn"
                class="tw-bg-blueGray-800 tw-text-white active:tw-bg-blueGray-600 tw-text-sm tw-font-bold tw-uppercase tw-px-6 tw-py-3 tw-rounded tw-shadow hover:tw-shadow-lg tw-outline-none focus:tw-outline-none tw-mr-1 tw-mb-1 tw-w-full tw-ease-linear tw-transition-all tw-duration-150"
                type="button"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
