import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getInital'
})
export class GetInitalPipe implements PipeTransform {
  transform(value: string) {    
    if (value) {
      // return value.split('')[0];
      return value.split(' ').map(n => n[0]).join('');
    }
  }
}


