import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService, UtilityService } from "src/app/shared";

@Component({
  selector: "app-auth-main",
  templateUrl: "./authmain.component.html",
})
export class AuthMainComponent implements OnInit {
  logo: any;
  constructor(
    private apiService: ApiService,
    public utilityService: UtilityService,
    private spinnerService: NgxSpinnerService
  ) {}
  ngOnInit() {}
}
