import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import * as fromShared from '../../../shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private api: fromShared.ApiService,
    private router: Router,
    private util: fromShared.UtilityService,
  ) {}

  checkAuthentication() {
    if (this.util.isAuthenticated) {
      return true;
    }
    // this.appState.logOut();
    this.router.navigate(['auth/login'])

    return false;
  }

  canLoad() {
    return this.checkAuthentication();
  }

  canActivate() {
    return this.checkAuthentication();
  }
}
