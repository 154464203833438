import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModule } from "../../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthMainComponent } from "./authmain.component";
import { LoginComponent } from "./login/login.component";
import { AuthRoutingModule } from "./auth-routing.module";

@NgModule({
  declarations: [AuthMainComponent, LoginComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    AuthRoutingModule,
  ],
  entryComponents: [],
})
export class AuthModule {}
