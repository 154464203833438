<div class="tw-p-8" *ngIf="!data.note && !data.isOkayBtn && !data.title">
  <div class="message">{{ data.message }}</div>
  <div mat-dialog-actions class="tw-justify-end tw-p-4">
    <button mat-raised-button [mat-dialog-close]="true" class="bg-clr-gray">
      No
    </button>
    <button mat-raised-button color="primary" (click)="dialogClose()">
      Yes
    </button>
  </div>
</div>

<div *ngIf="data.title && !data.isOkayBtn">
  <div class="tw-p-8">
    <div class="message">{{ data.title }}</div>
    <p>{{ data.text }}</p>
    <div mat-dialog-actions class="tw-justify-end tw-p-4">
      <button mat-raised-button [mat-dialog-close]="true" class="bg-clr-gray">
        No
      </button>
      <button mat-raised-button color="primary" (click)="dialogClose()">
        Yes
      </button>
    </div>
  </div>
</div>

<div *ngIf="data.note && !data.isOkayBtn">
  <div class="tw-p-8">
    <div class="message">{{ data.message }}</div>
    <p style="color: red">{{ data.note }}</p>
    <div class="tw-flex tw-justify-end tw-mt-4 tw-space-x-4">
      <button mat-raised-button color="primary" (click)="dialogClose('yes')">
        Mark as completed
      </button>
      <button class="btn btn-primary" (click)="dialogClose('dashboard')">
        Yes
      </button>
      <button
        mat-raised-button
        [mat-dialog-close]="true"
        class="bg-clr-gray"
        (click)="dialogClose('no')"
      >
        No
      </button>
    </div>
  </div>
</div>

<div *ngIf="data.isOkayBtn">
  <div class="tw-p-8">
    <div class="message">{{ data.message }}</div>
    <div mat-dialog-actions class="tw-justify-end tw-p-4">
      <button mat-raised-button [mat-dialog-close]="true" class="bg-clr-gray">
        OK
      </button>
    </div>
  </div>
</div>
