import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  HttpClient,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ApiService implements HttpInterceptor {
 
  URL = environment.baseUrl;
  APIURL = environment.apiUrl;
  LOCALAPIURL = environment.localApiUrl;
  V3APIURL = environment.apiV3Url;
  urls = {
    getLogo: "v3/setting/get-logo",
    auth: {
      login: "v1/login",
    },
    subscription: {
      active: "v3/tracking/subscription-active",
      expiring: "v3/tracking/subscription-will-expire",
      expired: "v3/tracking/subscription-expired",
      summary: "v3/tracking/providers-subscription-summary",
    },
    newproviders: {
      get: "v3/tracking/new-providers",
    },
    stripepayout: {
      get: "v3/stripe/get-enabled-users",
      enableAutoPayment: "v3/stripe/enable-auto-payment",
    },
    clinics: {
      last_activity: "v3/tracking/last-clinic-activity",
      clinic_providers: "v3/tracking/clinic-providers",
      clinic_providers_last_activity: "v3/tracking/providers-activity",
    },
    automation: {
      getEpcsNiravId: "v3/super/getEpcsNiravId",
      getClinicList: "v3/super/getClinicList",
      getProviderList: "v3/super/getProviderList",
      addClinicDeeplink: "v3/super/addClinicDeeplink",
      addEpcsUser: "v1/analytics/add-user-epcs",
      addEpcsAdmin: "v1/admin/add-admin-private-practice",
    },
    workflow: {
        createTeam            : "v3/clinic-creation/sql/workflow/team",
        addMandatoryData      : "v3/clinic-creation/sql/workflow/mandatory",
        createBitbucket       : "v3/clinic-creation/bitbucket/workflow/execute",
        createS3bucket        : "v3/clinic-creation/aws/workflow/bucket/execute",
        createCodepipeline    : "v3/clinic-creation/aws/workflow/pipeline/execute",
        createCloudfront      : "v3/clinic-creation/aws/workflow/cloudfront/execute",
        createBranchLinks     : "v3/clinic-creation/branchio/workflow/execute",
        uploadLogo            : "v3/clinic-creation/workflow/logo-upload",
        uploadSignature       : "v3/clinic-creation/workflow/signature-upload"
    },
    status: {
      getPatientAndProviderList: "v3/super/getPatientAndProviderList",
      manuallyApproveDisapproveUser: "v3/super/manuallyApproveDisapproveUser",
    },
  };
  get currentDate() {
    return new Date();
  }
  constructor(private http: HttpClient) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let auth =
      localStorage.getItem("token") !== null
        ? `Bearer ${localStorage.getItem("token")}`
        : "";
    let headerOptions = new HttpHeaders({
      "App-Origin": environment.team_id,
      Authorization: auth,
    });
    // debugger
    let dubReq: any;
    if (req.url.includes("s3")) {
      let options = new HttpHeaders({
        // 'App-Origin': environment.team_id
        // 'Content-Type': 'image/png'
      });
      dubReq = req.clone({
        url: req.url,
        headers: options,
      });
    } else if (
      req.url.includes("draftDownloadPdfReport") ||
      req.url.includes("draftDownloadSuperbill")
    ) {
      dubReq = req.clone({
        url: this.APIURL + req.url,
        headers: headerOptions,
        responseType: "arraybuffer",
      });
    } else if (req.url.includes("videocall/draftDownloadPdfReport")) {
      dubReq = req.clone({
        url: this.URL + req.url,
        headers: headerOptions,
        responseType: "arraybuffer",
      });
    } else if (req.url.includes("messages")) {
      dubReq = req.clone({
        url: this.V3APIURL + req.url,
        headers: headerOptions,
      });
    } else if (req.url.includes("v3/patient/download")) {
      dubReq = req.clone({
        url: this.V3APIURL + req.url,
        headers: headerOptions,
        responseType: "blob",
      });
    } else if (req.url.includes("v3/psych/download")) {
      dubReq = req.clone({
        url: this.V3APIURL + req.url,
        headers: headerOptions,
        responseType: "blob",
      });
    } else if (req.url.includes("v3/")) {
      dubReq = req.clone({
        url: this.V3APIURL + req.url,
        headers: headerOptions,
      });
    } else {
      dubReq = req.clone({
        url: this.URL + req.url,
        headers: headerOptions,
      });
    }

    return next.handle(dubReq);
  }

  login(payload) {
    return this.http.post(this.urls.auth.login, payload);
  }
  getActivePsychSubscription(payload) {
    return this.http.get(this.urls.subscription.active, { params: payload });
  }
  getPsychSummary(payload) {
    return this.http.get(this.urls.subscription.summary, { params: payload });
  }
  getExpiringPsychSubscription(payload) {
    return this.http.get(this.urls.subscription.expiring, { params: payload });
  }
  getExpiredPsychSubscription(payload) {
    return this.http.get(this.urls.subscription.expired, { params: payload });
  }
  getNewProviders(payload) {
    return this.http.get(this.urls.newproviders.get, { params: payload });
  }
  getEnabledUsers(payload) {
    return this.http.get(this.urls.stripepayout.get, { params: payload });
  }
  saveEnableAutoPayment(payload) {
    return this.http.post(this.urls.stripepayout.enableAutoPayment, payload);
  }
  getClinicLastActivity(payload) {
    return this.http.get(this.urls.clinics.last_activity, { params: payload });
  }
  getClinicProviders(payload) {
    return this.http.get(this.urls.clinics.clinic_providers, {
      params: payload,
    });
  }
  getClinicProvidersLastActivity(payload) {
    return this.http.get(this.urls.clinics.clinic_providers_last_activity, {
      params: payload,
    });
  }
  addClinicDeeplink(payload) {
    return this.http.post(this.urls.automation.addClinicDeeplink, payload);
  }
  addEpcsUser(payload) {
    return this.http.post(this.urls.automation.addEpcsUser, payload);
  }
  addEpcsAdmin(payload) {
    return this.http.post(this.urls.automation.addEpcsAdmin, payload);
  }
  getClinicList(payload) {
    return this.http.get(this.urls.automation.getClinicList, {
      params: payload,
    });
  }
  getProviderList(payload) {
    return this.http.get(this.urls.automation.getProviderList, {
      params: payload,
    });
  }
  getEpcsNiravId(payload) {
    return this.http.get(this.urls.automation.getEpcsNiravId, {
      params: payload,
    });
  }
  getPatientAndProviderList(payload) {
    return this.http.get(this.urls.status.getPatientAndProviderList, {
      params: payload,
    });
  }
  manuallyApproveDisapproveUser(payload) {
    return this.http.post(
      this.urls.status.manuallyApproveDisapproveUser,
      payload
    );
  }


  public addTeamData(team:any) {
    return this.http.post(this.urls.workflow.createTeam,team)
  }

  public addMandatoryData(team_id:number) {
    return this.http.post(this.urls.workflow.addMandatoryData,{team_id:team_id})

  }

  public executeBitBucketAutomation(branchName:string,teamId:number ) {
    return this.http.post(this.urls.workflow.createBitbucket,{branchName:branchName,teamId:teamId})

  }

  public executeBucketAutomation(bucketName:string) {
    return this.http.post(this.urls.workflow.createS3bucket,{bucketName:bucketName })

  }
  public executePipelineAutomation(pipelineName:string) {
    return this.http.post(this.urls.workflow.createCodepipeline,{pipelineName:pipelineName})

  }

  public executeCloudfrontAutomation(pipelineName:string) {
    return this.http.post(this.urls.workflow.createCloudfront,{pipelineName:pipelineName})

  }

  public executeBranchioAutomation(url:string) {
    return this.http.post(this.urls.workflow.createBranchLinks,{url:url})

  }
  public uploadLogo(formData:any) {
    return this.http.post(this.urls.workflow.uploadLogo,formData);

  }

  public  uploadSignature(formData: FormData) {
    return this.http.post(this.urls.workflow.uploadSignature,formData);
  }
}
